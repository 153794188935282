<template>
  <div class="container">
    <app-bar
      title="Hasil & Monitoring"
      :noDivider="true"
      previousLink="/"
      class="d-none" />

    <div class="row">
      <div class="col-12 px-3">
        <header v-if="!search" class="d-flex align-items-center justify-content-between py-4">
          <span>Hasil Lab</span>
          <div
            type="button"
            @click="search = true"
          >
            <img
              src="@/assets/icon/search-outline.svg"
              alt="Icon"
            />
          </div>
        </header>
        <div v-else class="row py-3">
          <div class="col-md-4">
            <div class="form-search input-group rounded-pill">
              <input
                type="text"
                class="form-control bg-transparent"
                placeholder="Cari hasil lab...."
                name="search"
                v-model="keyword"
                autocomplete="off">
              <span class="input-group-text bg-transparent border-0">
                  <div
                    class="box-icon"
                    type="button"
                    @click="keyword = ''"
                  >
                    <i v-if="keyword" class="ri-close-circle-fill fs-14 color-neutral-700"></i>
                    <i v-else class="ri-search-line fs-14 color-neutral-500"></i>
                  </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 px-3">
        <!-- Informasi -->
        <div class="card-info p-3 d-flex flex-row align-items-start bg-primary-50 border-0 text-primary mb-3">
          <i class="ri-information-line ri-xl mt-2 icon-info"></i>
          <div class="ms-2 flex-fill">
            <h5>Informasi</h5>
            <p class="mb-2">
              Untuk yang mengingikan hasil lab 4 tahun terakhir silahkan hubungi admin
            </p>
            <a href="https://wa.me/6281133321888" target="_blank" class="btn btn-primary float-end">Hubungi Admin</a>
          </div>
        </div>

        <!-- Monitoring -->
        <section class="monitoring bg-primary-50 mb-3">
          <div class="monitoring-group d-flex align-items-center gap-2">
            <div class="monitoring-group-image">
              <img
                src="@/assets/icon/monitoring.svg"
                alt="Icon">
            </div>
            <h5 class="monitoring-group-title fw-bold color-neutral-900 mb-0">Hasil Monitoring</h5>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6">
                <router-link
                  :to="{ name: 'Monitoring' }"
                  class="btn btn-primary btn-detail py-2"
                >
                  <span class="fw-600 fs-12 lh-18">Pribadi</span>
                </router-link>
              </div>

              <div class="col-6">
                <router-link
                  :to="{ name: 'MonitoringMcu' }"
                  class="btn btn-primary btn-detail py-2"
                >
                  <span class="fw-600 fs-12 lh-18">MCU Instansi</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>

        <!-- Hasil -->
        <section>
          <Loading v-if="loading.hasil" />
          <div
            v-else-if="listHasil.length === 0"
            class="empty">
            <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
              <div class="not-empty d-flex flex-column align-items-center pt-3">
                  <div class="mb-4">
                      <img src="@/assets/icon/report-lab.svg" alt="Icon">
                  </div>
                  <div class="fw-700 fs-18 color-neutral-900">Tidak ada Hasil Lab</div>
                  <p class="text-center fw-400 fs-14 lh-24 color-neutral-600 mb-0" style="max-width: 255px">Anda belum mempunyai hasil lab pemeriksaan</p>
              </div>
            </div>
          </div>
          <div v-else class="result-list d-flex flex-column gap-3">
            <strong class="color-neutral-900">Hasil Pemeriksaan</strong>
            <div v-for="(hasil, idx) in listHasil" :key="hasil.no_lab" class="result-item bg-neutral-200">
              <!-- Status Konfirmasi === 0 -->
              <div
                v-if="hasil.status_konfirmasi == 0"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#modalKonfirmasi' + idx"
                @click="onHandleConfirm">
                <div class="result-item-group d-flex align-items-center justify-content-between mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="result-item-icon bg-info-500 d-flex align-items-center justify-content-center rounded-circle">
                      <img
                        src="@/assets/icon/note-outline.svg"
                        alt="">
                    </div>
                    <h5 class="result-item-title fw-500 color-neutral-900 mb-0">{{ hasil.no_lab }}</h5>
                  </div>
                  <img
                    src="@/assets/icon/arrow-right-outline.svg"
                    alt="Icon"
                  />
                </div>
                <div class="result-item-info bg-white d-flex align-items-center justify-content-between fw-400 fs-12 lh-22">
                  <span class="color-secondary-500">{{ hasil.nama_cabang }}</span>
                  <span class="color-neutral-800">{{ hasil.tgl }}</span>
                </div>
              </div>

              <!-- Status Konfirmasi === 1 -->
              <router-link
                v-else
                :to="{ name: 'DetailHasil', params:{ id_cabang: hasil.cabang, no_lab: hasil.no_lab } }">
                <div class="result-item-group d-flex align-items-center justify-content-between mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="result-item-icon bg-info-500 d-flex align-items-center justify-content-center rounded-circle">
                      <img
                        src="@/assets/icon/note-outline.svg"
                        alt="">
                    </div>
                    <h5 class="result-item-title fw-500 color-neutral-900 mb-0">{{ hasil.no_lab }}</h5>
                  </div>
                  <img
                    src="@/assets/icon/arrow-right-outline.svg"
                    alt="Icon"
                  />
                </div>
                <div class="result-item-info bg-white d-flex align-items-center justify-content-between fw-400 fs-12 lh-22">
                  <span class="color-secondary-500">{{ hasil.nama_cabang }}</span>
                  <span class="color-neutral-800">{{ hasil.tgl }}</span>
                </div>
              </router-link>

              <!-- Modal -->
              <div class="modal fade" :id="'modalKonfirmasi' + idx" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                          <span class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                            Pernyataan
                          </span>
                        </div>
                      </div>
                      <div class="desc-group mt-3 mt-md-0">
                        <p class="fw-400 fs-14 lh-24 color-neutral-600" style="text-align: justify" v-html="confirmMessage" />
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                      <button type="button" class="btn btn-danger col py-2" data-bs-dismiss="modal">Tolak</button>
                      <button
                        class="btn btn-primary col py-2"
                        data-bs-dismiss="modal"
                        @click="prepareConfirm(hasil)">
                        Setuju
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import AppBar from '@/components/AppBar.vue'
import { nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'
import { getHasil, listHasil, loading, confirmHasil } from '@/core/hasil'
import Loading from '@/components/utility/Loading'
import { settingMessage, getSetting } from '@/core/setting'
import { useRouter } from 'vue-router'
import { showNotif } from '@/core/utility'

export default {
  components: {
    AppBar,
    Loading
  },
  setup () {
    const router = useRouter()
    const confirmMessage = ref('')
    const onHandleConfirm = async () => {
      await getSetting('konfirmasi_hasil')
      confirmMessage.value = settingMessage.value
    }

    const prepareConfirm = async (hasil) => {
      try {
        const result = await confirmHasil(hasil.cabang, hasil.no_lab)
        if (result.status) {
          router.push({
            name: 'DetailHasil',
            params: {
              id_cabang: hasil.cabang,
              no_lab: hasil.no_lab
            }
          })
        }
      } catch (error) {
        let msg = 'Gagal kirim data'
        if (error.response?.data?.message) {
          msg = error.response.data.message
        }

        showNotif({
          status: 'error',
          message: msg
        })
      }
    }

    onMounted(() => {
      getHasil()
      nextTick(() => {
        title.value = 'Hasil Lab'
      })
    })

    const search = ref(false)
    const keyword = ref('')

    return {
      listHasil,
      loading,
      search,
      keyword,
      onHandleConfirm,
      confirmMessage,
      prepareConfirm
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-info {
  line-height: 0.4;
}

.form-search {
  border: 1px solid #E2E8F0;

  .form-control, .input-group-text {
    height: 34px;
  }

  .form-control:focus{
    box-shadow: none;
  }

  .form-control::placeholder {
    color: #A0AEC0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}

.monitoring {
  padding: 20px 8px 8px;
  border-radius: 12px;

  &-group {
    padding-left: 8px;
    margin-bottom: 20px;

    &-title {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .btn-detail {
    width: 100%;
  }
}

.result {
  &-item {
    padding: 12px;
    border-radius: 12px;

    &-icon {
      width: 36px;
      height: 36px;
    }

    &-info {
      padding: 8px;
      border-radius: 11px;
    }
  }
}
</style>
