import { ref, reactive } from '@vue/reactivity'
import axios from '../plugins/axios'

export const settingMessage = ref('')
export const loading = reactive({
  setting: false
})

export const getSetting = async (payload) => {
  try {
    loading.setting = true
    const { data } = await axios.get('app/getsetting', {
      params: {
        setting: payload
      }
    })

    if (data.success) {
      settingMessage.value = data.data[0].val
    }
  } catch (error) {
    console.error('Error dari setting get setting = ', error)
    throw error
  } finally {
    loading.setting = false
  }
}
